export default ({ data } = {}) => {
  return {
    title: '记录',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '赠送时间',
            key: 'createTime',
          },
          {
            name: '赠送人',
            key: 'createByName',
          },
        ],
      },
    ],
  }
}
