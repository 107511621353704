 

export default ({ data } = {}) => {
    const useCodeRecordList = data.useCodeRecordList
    const arr = useCodeRecordList && useCodeRecordList.map((item,i)=>{
         return  {
            name: item.businessName,
            key: 'createTime' + i,
            width:'800px',
            right: ()=>item.useTime,
            showRight:true
          }
    })
    return {
      title: '使用记录',
      form: [
        {
          type: 'textGroup',    
          value: arr || []
        },
      ],
    }
  }
  