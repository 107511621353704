export default ({ data }) => {
  return {
    title: '门店自提',
    form: [
      {
        type: 'textGroup',
        value: [
          {
            name: '收件人',
            value: data.receiverName
          },
          {
            name: '收货人电话',
            value: data.receiverPhone
          },
          {
            name: '门店自提',
            value: data.pickUpName  
          },
          {
            name: '自提地址',
            width: '100%',
            display: data.receiverProvince,
            value:data.pickUpAddress
          },
          {
            name: '订单备注',
            key: 'remark',
            display: data.remark
          }
        ]
      }
    ]
  }
}
