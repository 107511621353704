export default ({ data, type }) => {
  console.log('--data--',data)
  return {
    title: '基础',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '店铺名称',
            key: 'shopName',
            display: data.shopName,
          },
          {
            name: '核销截止时间',
            value: data['orderItemList'][0]['effectiveEndTime'],
            display: data['orderItemList'][0]['effectiveEndTime'],
          },
          {
            name: '用户姓名',
            key: 'buyName',
            display: data.buyName,
          },
          {
            name: '订单编号',
            key: 'orderSn',
            display: data.orderSn,
          },
          {
            name: '登录手机',
            key: 'buyPhone',
            display: data.buyPhone,
          },
          {
            name: '订单状态',
            value:
              {
                0: '待付款',
                1: type == 'hotel' ? '待入住' : '待使用',
                2:  data.pickUpId ? "待取货" : '待发货',
                3: '待签收',
                4: '待评价',
                5: '已完成',
                6: '已关闭',
                102: '待确认',
                101: '出票中',
              }[data.status] || '',
            display: true,
          },
          {
            name: '支付方式',
            value: { 1: '支付宝', 2: '微信', 3: '农行支付', 4: '0元付' }[data.payType],
            display: data.payType !== null,
          },
          {
            name: '订单类型',
            value: ["普通订单","秒杀订单","推广订单","分销订单"][data.orderType],
            display:true
          },
          {
            name: '分销人',
            value: data.shareUserPhone,
            display: data.orderType == 3
          },
          {
            name: '订单佣金',
            value: data.commissionAmount,
            display: data.orderType == 3
          },
          {
            name: '订单备注',
            value: data.remark,
            display: data.remark,
            width: '100%',
          },
        ].filter((e) => e.display),
      },
    ],
  }
}
