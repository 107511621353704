export default ({ data, type }) => {
  return {
    title: '商品信息',
    form: [
      {
        type: 'textGroup',
        labelCol: { span: 3 },
        wrapperCol: { span: 24 },
        value: [
          {
            name: '订单编号',
            key: 'orderSn',
            // value:
            //   {
            //     1: '蓝票',
            //     2: '红票',
            //   }[data.invoiceType] || '',
          },
          {
            name: '状态',
            key: 'buyerName',
            value:
              {
                0: '待支付',
                1: '待使用',
                5: '已完成',
                6: '已关闭',
              }[data.status] || '',
          },
          {
            name: '店铺名称',
            key: 'shopName',
          },
          {
            name: '商品类型',
            key: 'fromType',
            value:
              {
                12: '景点门票',
                13: '酒店民宿',
                14: '组合套餐',
              }[data.fromType] || '',
          },
          {
            name: '商品名称',
            key: 'productName',
          },
          {
            name: '商品数量',
            key: 'productQuantity',
          },
          {
            name: '有效期',
            key: 'buyerBankName',
            value: `${data.effectiveStartTime}-${data.effectiveEndTime}`,
          },
        ],
      },
    ],
  }
}
