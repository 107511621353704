
function getTableColumns(data) {
    return [
        data
    ]
}

export default function () {
    const { data, type } = arguments[0]
    return {
        class: 'order-table',
        title: type == 2 ? "车票信息" : '船票信息',
        form: [
            {
                type: 'table',
                wrapperCol: 24,
                dataSource: getTableColumns(data),
                showPagination: false,
                columns: [
                    {
                        title: '航班号',
                        dataIndex: 'busCode',
                        display: type != 2,
                    },
                    {
                        title: '出发终点站',
                        dataIndex: 'a1',
                        display: true,
                        customRender: (text, records, index, h) => {
                            return `${records.startStationName}-${records.endStationName}`
                        },
                    },
                    {
                        title: '票种类型',
                        display: type != 2,
                        dataIndex: 'ticketTypeName',
                        customRender: (text, records, index, h) => {
                            if (type == 2) {
                                return records.ticketTypeName
                            } else {
                                if (records.groupType == 1) {
                                    return '旅客'
                                } else if (records.groupType == 2) {
                                    return '5-7座客车及随车人员'
                                } else if (records.groupType == 3) {
                                    return records.orderItemList[0].ticketTypeName
                                }
                            }
                        },
                    },
                    {
                        title: '舱位',
                        display: type != 2,
                        dataIndex: 'seatTypeName',

                    },
                    {
                        title: '数量',
                        display: true,
                        dataIndex: 'productQuantity',
                        customRender: (text, records, index, h) => {
                            return records.orderItemList.length
                        },
                    },

                ].filter((e) => e.display),
            },
        ],
    }
}
