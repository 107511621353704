export default ({ data } = {}) => {
  const useCodeRecordList = data.userCodeVOList
  const arr =
    useCodeRecordList &&
    useCodeRecordList.map((item, i) => {
      return {
        name: `${item.businessName}    ${item.productName}`,
        key: 'createTime' + i,
        width: '800px',
        right: () => item.usedTime,
        showRight: true,
      }
    })
  return {
    title: '使用记录',
    form: [
      {
        type: 'textGroup',
        value: arr || [],
      },
    ],
  }
}
