export default function () {
  const { data, type } = arguments[0]
  return {
    class: 'order-table',
    form: [
      {
        type: 'table',
        wrapperCol: 24,
        dataSource: data.visitorList,
        showPagination: false,
        columns: [
          {
            title: '姓名',
            align: 'left',
            dataIndex: 'concatName',
          },
          {
            title: '手机号',
            dataIndex: 'concatPhone',
          },
          {
            title: '身份证',
            dataIndex: 'concatIdCard',
          },
        ],
      },
    ],
  }
}
